<template>
  <v-container id="dashboard" fluid tag="section">
    <template>
      <div class="d-flex mb-4 mt-4">
        <v-btn color="primary" class="mr-0" @click="downloadLog">
          <v-icon class="mr-1">mdi-download</v-icon> Download Log</v-btn
        >
        <v-dialog v-model="dialogDelete" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="ml-2" dark v-bind="attrs" v-on="on"
              ><v-icon class="mr-2">mdi-clipboard-text-outline</v-icon> Clear
              Log</v-btn
            >
          </template>
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to clear the log?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete = false"
                >Cancel</v-btn
              >
              <v-btn color="error" @click="deleteConfirmBtn">Clear</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>
        <div class="d-flex mr-4">
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details
            class="pt-0 mr-2"
          ></v-text-field>
          <v-button
            class="self-center"
            @click="handleSearchBtn"
            style="align-self: center; cursor: pointer"
          >
            <v-icon class="self-center">mdi-magnify</v-icon>
          </v-button>
        </div>

        <v-menu
          bottom
          left
          offset-y
          origin="top right"
          transition="scale-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="ml-2"
              color="#7F7F7F"
              style="background-color: white; border: 1px solid #7f7f7f"
              min-width="0"
              text
              v-bind="attrs"
              v-on="on"
            >
              {{ filterLevel === "" ? "ALL" : filterLevel }}
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list :tile="false" nav>
            <div>
              <app-bar-item>
                <v-btn
                  background="transparent"
                  style="
                    background-color: transparent !important;
                    border: none;
                    box-shadow: none;
                  "
                  @click="
                    () => {
                      handleChangeFilterLevel('');
                    }
                  "
                  :style="
                    filterLevel === ''
                      ? { color: '#ff6a13' }
                      : { color: '#7F7F7F' }
                  "
                >
                  ALL
                </v-btn>
              </app-bar-item>
            </div>
            <div>
              <app-bar-item>
                <v-btn
                  background="transparent"
                  color="black"
                  style="
                    background-color: transparent !important;
                    border: none;
                    box-shadow: none;
                  "
                  @click="
                    () => {
                      handleChangeFilterLevel('INFO');
                    }
                  "
                  :style="
                    filterLevel === 'INFO'
                      ? { color: '#ff6a13' }
                      : { color: '#7F7F7F' }
                  "
                >
                  Info
                </v-btn>
              </app-bar-item>
            </div>
            <div>
              <app-bar-item>
                <v-btn
                  background="transparent"
                  style="
                    background-color: transparent !important;
                    border: none;
                    box-shadow: none;
                  "
                  @click="() => handleChangeFilterLevel('NOTICE')"
                  :style="
                    filterLevel === 'NOTICE'
                      ? { color: '#ff6a13' }
                      : { color: '#7F7F7F' }
                  "
                >
                  Notice
                </v-btn>
              </app-bar-item>
            </div>
            <div>
              <app-bar-item>
                <v-btn
                  background="transparent"
                  color="black"
                  style="
                    background-color: transparent !important;
                    border: none;
                    box-shadow: none;
                  "
                  @click="() => handleChangeFilterLevel('ERROR')"
                  :style="
                    filterLevel === 'ERROR'
                      ? { color: '#ff6a13' }
                      : { color: '#7F7F7F' }
                  "
                >
                  Error
                </v-btn>
              </app-bar-item>
            </div>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="tableData"
        hide-default-footer
        :items-per-page="itemsPerPage"
        :loading="isLoading"
        loading-text="Loading... Please wait"
        class="elevation-1"
      >
        <template v-slot:item.level_name="{ item }">
          <label :style="{ color: levelColor[item.level_name] }">
            {{ item.level_name }}
          </label>
        </template>
        <!-- <template v-slot:item.createdon="{ item }">
          <label>
            {{ new Date(item.createdon).toLocaleString("en-GB") }}
          </label>
        </template> -->
      </v-data-table>
      <Pagination
        :page.sync="page"
        :itemsPerPage.sync="itemsPerPage"
        :pages="pages"
        :pageItems="pageItems"
        :totalNumberOfItems="totalNumberOfItems"
      />
    </template>
  </v-container>
</template>

<script>
import {
  APIdoGetLogs,
  APIdoRemoveLogs,
  APIdoDownloadLogs,
} from "@/api/common/api";
import { mapGetters } from "vuex";
import Pagination from "../component/pagination/";

export default {
  name: "DashboardDashboard",

  data() {
    return {
      totalNumberOfItems: 0,
      search: "",
      page: 1,
      itemsPerPage: 100,
      isLoading: false,
      pages: 1,
      pageItems: [100, 200, 300],
      filterLevel: "",
      selected: [],
      levelColor: {
        INFO: "#1867C0",
        NOTICE: "#FF6A13",
        ERROR: "#AB1713",
      },
      headers: [
        {
          text: "Created on",
          value: "createdon",
        },
        { text: "Level", value: "level_name" },
        { text: "URL", value: "url" },
        { text: "Username", value: "username" },
        { text: "Message", value: "message", width: "35%" },
        { text: "IP", value: "ip" },
      ],
      tableData: [],
      dialogDelete: false,
    };
  },
  components: {
    Pagination,
  },
  computed: {
    ...mapGetters({
      getUserDetails: "getUserData",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Create new user" : "Edit User Details";
    },
  },
  mounted() {
    if (this.getUserDetails.user_type !== "admin") this.$router.push("/");
    this.getLogs(this.page, this.itemsPerPage, this.filterLevel);
  },
  methods: {
    getLogs(page, limit, level, term) {
      this.isLoading = true;
      this.tableData = [];
      APIdoGetLogs(page, limit, level, term)
        .then((response) => {
          this.isLoading = false;
          this.pages = response.data.pages;
          this.totalNumberOfItems = response.data.total;
          this.tableData = response.data.results;
        })
        .catch((error) => {
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },
    handleSearchBtn() {
      this.getLogs(this.page, this.itemsPerPage, this.filterLevel, this.search);
    },
    refresh() {
      this.page = 1;
      this.itemsPerPage = 100;
      this.getLogs(this.page, this.itemsPerPage, this.filterLevel, this.search);
    },
    handleChangeFilterLevel(level) {
      this.filterLevel = level;
      this.getLogs(this.page, this.itemsPerPage, this.filterLevel, this.search);
    },
    handleItemsPerPageChange() {
      this.page = 1;
      this.getLogs(this.page, this.itemsPerPage, this.filterLevel, this.search);
    },
    handlePageChange() {
      this.page = parseInt(this.page);
      this.getLogs(this.page, this.itemsPerPage, this.filterLevel, this.search);
    },
    deleteLogs() {
      this.dialogDelete = true;
    },

    deleteConfirmBtn() {
      APIdoRemoveLogs()
        .then((response) => {
          this.page = 1;
          this.getLogs(
            this.page,
            this.itemsPerPage,
            this.filterLevel,
            this.search
          );
        })
        .catch((err) => {
          this.getLogs(
            this.page,
            this.itemsPerPage,
            this.filterLevel,
            this.search
          );
        });
      this.closeDelete();
    },
    downloadLog() {
      APIdoDownloadLogs()
        .then((response) => {
          const disposition = response.headers["content-disposition"];
          var filename = "";
          if (disposition && disposition.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          var blob = new Blob([response.data], { type: "text/plain" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        })
        .catch((error) => {
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.viewItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
};
</script>
